function loadingAnimation() {
    const loading = document.getElementById('loading');
    const loadingIcon = document.querySelector('.loading-icon');
    
    // loadingIcon にアニメーションを追加
    loadingIcon.style.animationName = 'fadeout-icon';

    loadingIcon.addEventListener('animationend', () => {
        loading.style.animationName = 'fadeout-loading';
    })

    return;
}

setTimeout(() => {
    loadingAnimation();
    return;
}, 3000)

window.onload = loadingAnimation;